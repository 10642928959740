import { useStorage } from '@vueuse/core';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useSearchbar() {
  const searchHistory = useStorage('searchhistory', []);
  const site = useSiteIdent();

  async function searchSuggestions(searchInput: string) {
    try {
      const result = await useSecureSessionPost(
        `/api/${site}/search/suggestions`,
        { search: searchInput },
      );
      if (!result?.suggestions) {
        return null;
      }
      return result;
    } catch (e) {
      handleLoadingError(e);
      return null;
    }
  }

  function addSearchQueryToSearchHistory(searchInput: string): void {
    const trimedSearchInput = searchInput.trim();
    const lowercaseSearchHistory = searchHistory.value.map(function (value) {
      return value.toLowerCase();
    });

    const index = lowercaseSearchHistory.indexOf(
      trimedSearchInput.toLowerCase(),
    );
    if (index > -1) searchHistory.value.splice(index, 1);
    searchHistory.value.push(trimedSearchInput);
    navigateTo(`/shop/search/${encodeURIComponent(trimedSearchInput)}`);
  }

  function clearSearchHistory(): void {
    searchHistory.value = [];
  }

  return {
    searchHistory,
    addSearchQueryToSearchHistory,
    clearSearchHistory,
    searchSuggestions,
  };
}
